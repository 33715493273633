import * as React from "react";
import { Helmet } from "react-helmet";
import { NavBar } from "../components/NavBar";
import { WhyContent } from "../components/WhyContent";

const WhyPage = () => {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dana For Cambridge</title>
      </Helmet>
      <NavBar />
      <WhyContent />
    </main>
  );
};

export default WhyPage;
